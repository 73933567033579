import React from 'react';
// import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
// import FaqHomeOne from './FaqHomeOne';
// import Incubation from './Incubation';
import FeaturesHomeOne from './FeaturesHomeOne';
import SponserHomeTwo from './SponserHomeTwo';
import FeaturesHomeOneUser from './FeaturesHomeOneUser';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ServicesHomeOne from './ServicesHomeOne';
import WorkPartHomeEight from './WorkPartHomeEight';
import CounterArea from './CounterArea';
import HeroHomeSix from './HeroHomeSix';
import SignupHomeEight from './SignupHomeEight';
import useToggle from '../../Hooks/useToggle';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne />
            {/* Getting Started */}
            <WorkPartHomeEight />
            {/* Stats */}
            {/* Designed to help */}
            <FeaturesHomeOne />
            <ServicesHomeOne />
            <FeaturesHomeOneUser />
            {/* All Features */}
            <CounterArea />
            {/* <FaqHomeOne /> */}
            {/* Download App */}
            <SponserHomeTwo />
            <HeroHomeSix />
            {/* <Incubation /> */}
            {/* Subscribe */}
            <SignupHomeEight />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
