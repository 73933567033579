import React from 'react';
import HomeOneHeader from '../Home/HomeOneHeader';
import BackToTop from '../BackToTop';
import SignupHomeEight from '../Home/SignupHomeEight';
import FooterHomeOne from '../Home/FooterHomeOne';
import SponserHomeTwo from '../Home/SponserHomeTwo';
import HeroAbout from './HeroAbout';
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToggle';

function AboutUs() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroAbout />
            <SponserHomeTwo />
            <SignupHomeEight />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default AboutUs;
