import React from 'react';

import HomeOneHeader from '../Home/HomeOneHeader';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../Home/FooterHomeOne';
import Forms from './Forms';
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToggle';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <Forms />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Contact;
