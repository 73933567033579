import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReCAPTCHA from 'react-google-recaptcha';

function Forms() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_no: '',
        subject: '',
        user_message: '',
    });
    const [captchaValue, setCaptchaValue] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!captchaValue) {
            NotificationManager.error('Please verify the CAPTCHA.', 'Error');
            return;
        }
        if (!captchaValue) {
            NotificationManager.error('Please verify the CAPTCHA.', 'Error');
            return;
        }

        setIsSubmitting(true);
        const jsonData = JSON.stringify({
            ...formData,
            captchaValue,
        });

        try {
            const response = await fetch('https://venta.betatest.store/api/contact-us', {
                method: 'POST',
                body: jsonData,
                headers: {
                    'Content-Type': 'application/json',
                    // any other headers that you might need
                },
            });

            if (!response.ok) {
                // if HTTP-status is 200-299
                NotificationManager.error('Error in sending message', 'Error');
            }
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_no: '',
                subject: '',
                user_message: '',
            });
            const data = await response.json(); // parses response to JSON
            setCaptchaValue(null);
            NotificationManager.success('Message sent successfully', 'Success');
            setIsSubmitting(false);
            console.log(data);
        } catch (error) {
            NotificationManager.error('Error in sending message', 'Error');
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <NotificationContainer />
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new adventure.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        Jodhpur, Rajasthan, India
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        +91 9782380389
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Support</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        help@ventadoor.com
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let’s Connect</h4>
                                <p>We will be in touch with you soon.</p>
                                <form onSubmit={handleSubmit} className="row">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name"
                                            value={formData.last_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone_no"
                                            placeholder="Phone Number"
                                            value={formData.phone_no}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="user_message"
                                            placeholder="How can we help?"
                                            value={formData.user_message}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                I agree to the{' '}
                                                <Link href="/privacy-policy">
                                                    Terms & Conditions
                                                </Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <ReCAPTCHA
                                            sitekey="6LcHYQIoAAAAAGPM-Zq_sj1HLrU4dCU4DLa5kDek" // Replace with your Site Key
                                            onChange={handleCaptchaChange}
                                        />
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input
                                            type="submit"
                                            name="submit"
                                            value={isSubmitting ? 'Sending...' : 'Send Message'}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="bisylms-map">
                <iframe
                    title="map"
                    src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;coord=39.966528,-75.158284&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland+(My%20Business%20Name)&amp;ie=UTF8&amp;t=p&amp;z=16&amp;iwloc=B&amp;output=embed"
                ></iframe>
            </div> */}
        </>
    );
}

export default Forms;
