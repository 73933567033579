import React from 'react';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                One-Stop Solution for <br /> Users & Professionals
                            </h3>
                            <p>
                                Ventadoor is designed to be easy to use for both clients and
                                professionals. The platform is clear, concise, and mobile-friendly,
                                with a variety of features that make it easy to find the services
                                you need and book an appointment.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-dumbbell text-white fa-lg" />
                                <span>1</span>
                            </div>
                            <h4 className="appie-title">More Business</h4>
                            <p>
                                The focus is on connecting clients with the right professionals and
                                getting the work done.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-clinic-medical text-white fa-lg" />
                                <span>2</span>
                            </div>
                            <h4 className="appie-title">Showcase your skills</h4>
                            <p>
                                Stand out from the crowd, Ventadoor provides you with a platform to
                                showcase your skills and experience, so you can grow your business.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-utensils-alt text-white fa-lg" />
                                <span>3</span>
                            </div>
                            <h4 className="appie-title">Transparent work</h4>
                            <p>
                                When a client books an appointment with a professional, they can
                                specify the scope of work and the desired deliverables.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <i className="fas fa-heartbeat text-white fa-lg" />
                                <span>4</span>
                            </div>
                            <h4 className="appie-title">Secure Payment</h4>
                            <p>
                                Ventadooe protects payment for both the client and the professional.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
