import React from 'react';
import Sponser1 from '../../assets/images/sponser-1.png';
import sponserShape from '../../assets/images/sponser-shape.png';

function SponserHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-sponser-area mt-4 mb-4 pb-100 ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Powered & Supported by</h3>
                                <p>We're incubated by GLIC.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-sponser-box d-flex justify-content-center">
                                <div className="sponser-item">
                                    <img src={Sponser1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sponser-shape mb-4">
                    <img src={sponserShape} alt="" />
                </div>
            </section>
        </>
    );
}

export default SponserHomeTwo;
