import React from 'react';

function Content() {
    return (
        <>
            <section className="appie-hero-area mt-100">
                <div className="container">
                    <p>PRIVACY POLICY</p>
                    <p>
                        Our management has created this Privacy Statement (Policy) in order to
                        demonstrate
                    </p>
                    <p>
                        our firm commitment to help our users better understand what information we
                        collect
                    </p>
                    <p>about them and what may happen to that information.</p>
                    <p>
                        The following discloses our information gathering and dissemination
                        practices for the
                    </p>
                    <p>Websites listed below.</p>
                    <p>OVERVIEW</p>
                    <p>
                        As part of the normal operation of our services we collect and, in some
                        cases, may
                    </p>
                    <p>
                        disclose information about you. This Privacy Policy describes the
                        information we collect
                    </p>
                    <p>
                        about you and what may happen to that information. By accepting this Privacy
                        Policy
                    </p>
                    <p>
                        and our Terms of Service, you expressly consent to our use and disclosure of
                        your
                    </p>
                    <p>
                        personal information in the manner prescribed in this Privacy Policy. This
                        Privacy Policy
                    </p>
                    <p>is incorporated into and subject to the terms of the Terms of Service.</p>
                    <p>
                        This Privacy Statement applies to all related sites, mobile application and
                        other software
                    </p>
                    <p>
                        applications. our all other worldwide sites and applications operate under
                        similar privacy
                    </p>
                    <p>
                        practices as described in this Privacy Policy, and subject to the
                        requirements of
                    </p>
                    <p>
                        applicable local law, we strive to provide a consistent set of privacy
                        practices throughout
                    </p>
                    <p>the online personals sites.</p>
                    <p>INFORMATION WE COLLECT</p>
                    <p>
                        Our primary goal in collecting personal information is to provide you with a
                        smooth,
                    </p>
                    <p>
                        efficient, and customized experience. This allows us to provide services and
                        features
                    </p>
                    <p>
                        that most likely meet your needs and to customize our service to make your
                        experience
                    </p>
                    <p>
                        easier. You agree that in order to assist our members to meet each other we
                        may
                    </p>
                    <p>
                        feature members&amp;#39; profiles in our editorials and newsletters that we
                        send out from time to
                    </p>
                    <p>
                        time to our members. Your address and telephone number are confidential and
                        will not
                    </p>
                    <p>
                        be posted in your profile. Your profile is available for other members to
                        view.
                    </p>
                    <p>.</p>
                    <p>
                        We automatically track certain information based upon your behavior on our
                        Websites
                    </p>
                    <p>
                        using cookies, internal functions and other devices. We use this information
                        for internal
                    </p>
                    <p>
                        research on our users&amp;#39; demographics, interests, and behavior to
                        better understand and
                    </p>
                    <p>
                        serve you and our community. This information may include and not limited to
                        the URL
                    </p>
                    <p>
                        that you just came from (whether this URL is on our site or not) which URL
                        you next go
                    </p>
                    <p>
                        to (whether this URL is on our site or not), what browser you are using, and
                        your IP
                    </p>
                    <p>address.</p>
                    <p>&nbsp;</p>
                    <p>Personally Identifiable Information.</p>
                    <p>
                        If you choose to create a Listing or make a booking, you must register via
                        the Site to
                    </p>
                    <p>
                        create an Account. When you (i) register with us through the Site to become
                        a Venue
                    </p>
                    <p>
                        Owner or Member, (ii) choose to add information to your profile webpage or
                        (iii) create a
                    </p>
                    <p>
                        Listing, we will ask you for personally identifiable information. This
                        refers to information
                    </p>
                    <p>
                        about you that can be used to contact or identify you (&amp;quot;Personal
                        Information&amp;quot;). Personal
                    </p>
                    <p>
                        Information includes, but is not limited to, your name, company name (if
                        applicable),
                    </p>
                    <p>
                        phone number, credit card or other billing information, email address and
                        home and
                    </p>
                    <p>
                        Venue postal addresses, as applicable. We use your Personal Information
                        mainly to
                    </p>
                    <p>provide the Services and administer your inquiries.</p>
                    <p>&nbsp;</p>
                    <p>
                        You may register to use the Services directly via the Site or by logging
                        into your account
                    </p>
                    <p>
                        with certain third party social media or social networking services
                        (&amp;quot;SOCIAL
                    </p>
                    <p>
                        NEYWORKING SITE&amp;quot;) (including, but not limited to, Facebook,
                        linkedin, twitter etc. ) via
                    </p>
                    <p>
                        the Site, as described herein. If you decide to register through an SOCIAL
                    </p>
                    <p>
                        NEYWORKING SITE, we will extract the Personal Information you have provided
                        to the
                    </p>
                    <p>
                        SOCIAL NEYWORKING SITE (such as your &amp;quot;real&amp;quot; name, email
                        address and other
                    </p>
                    <p>
                        information you make publicly available via the SOCIAL NEYWORKING SITE) from
                        the
                    </p>
                    <p>account you have with the applicable SOCIAL NEYWORKING SITE and use that</p>
                    <p>
                        information to create your Account. The specific information that we extract
                        may depend
                    </p>
                    <p>
                        on the privacy settings you have with the SOCIAL NEYWORKING SITE. You hereby
                    </p>
                    <p>
                        consent to our access to and collection of such personal information about
                        you. This is
                    </p>
                    <p>
                        however subject to the fact that the social media site may or may not let us
                        access your
                    </p>
                    <p>data when you log in through third party social media site</p>
                    <p>
                        We also collect the other information that you provide as part of
                        registration, creation of
                    </p>
                    <p>
                        Listings and the administration and personalization of your profile webpage
                        (e.g., without
                    </p>
                    <p>
                        limitation, zip code (on its own) and individual preferences)
                        (&amp;quot;Non-Identifying
                    </p>
                    <p>Information&amp;quot;).</p>
                    <p>
                        We use your Personal Information (in some cases, in conjunction with your
                        Non-
                    </p>
                    <p>
                        Identifying Information) mainly to provide the Services, complete your
                        transactions, and
                    </p>
                    <p>administer your inquiries.</p>
                    <p>
                        Certain Non-Identifying Information would be considered a part of your
                        Personal
                    </p>
                    <p>
                        Information if it were combined with other identifiers (for example,
                        combining your zip
                    </p>
                    <p>
                        code with your street address) in a way that enables you to be identified.
                        But the same
                    </p>
                    <p>
                        pieces of information are considered Non-Identifying Information when they
                        are taken
                    </p>
                    <p>
                        alone or combined only with other non-identifying information (for example,
                        your viewing
                    </p>
                    <p>
                        preferences). We may combine your Personal Information with Non-Identifying
                    </p>
                    <p>
                        Information and aggregate it with information collected from other Ventadoor
                        Users
                    </p>
                    <p>
                        (defined below) to attempt to provide you with a better experience, to
                        improve the quality
                    </p>
                    <p>
                        and value of the Services and to analyze and understand how our Site and
                        Service are
                    </p>
                    <p>
                        used. We may also use the combined information without aggregating it to
                        serve you
                    </p>
                    <p>
                        specifically, for instance to deliver a product to you according to your
                        preferences or
                    </p>
                    <p>restrictions.</p>
                    <p>
                        We also use your Personal Information to contact you with ventadoor
                        newsletters,
                    </p>
                    <p>
                        marketing or promotional materials and other information that may be of
                        interest to you.
                    </p>
                    <p>
                        If you decide at any time that you no longer wish to receive such
                        communications from
                    </p>
                    <p>
                        us, please follow the unsubscribe instructions provided in any of the
                        communications.
                    </p>
                    <p>
                        Log Data. When you visit the Site, whether you have registered via the Site
                        as a Venue
                    </p>
                    <p>
                        Owner or Member or are a non-registered user just browsing (any of these, a
                        “Ventadoor
                    </p>
                    <p>
                        User”), our servers automatically record information that your browser sends
                        whenever
                    </p>
                    <p>
                        you visit a website (“Log Data”). This Log Data may include information such
                        as your
                    </p>
                    <p>
                        computer&amp;#39;s Internet Protocol (“IP”) address, browser type or the
                        webpage you were
                    </p>
                    <p>
                        visiting before you came to our Site, pages of our Site that you visit, the
                        time spent on
                    </p>
                    <p>
                        those pages, information you search for on our Site, access times and dates,
                        and other
                    </p>
                    <p>
                        statistics. We use Google Analytics to collect, monitor and analyze this
                        information in
                    </p>
                    <p>
                        order to increase our Site&amp;#39;s functionality and user-friendliness,
                        and to better tailor it to our
                    </p>
                    <p>
                        visitor’s needs. Accordingly, Log Data is shared with Google, which has its
                        own privacy
                    </p>
                    <p>
                        policy addressing how it uses such information. We encourage you to review
                        this privacy
                    </p>
                    <p>
                        policy to understand how Google uses such information. We also use this
                        information to
                    </p>
                    <p>
                        verify that visitors to the Site meet the criteria required to process their
                        requests. We do
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        not treat Log Data as Personal Information unless we use it in association
                        with other
                    </p>
                    <p>
                        Personal Information. We may aggregate, analyze and evaluate Log Data for
                        the same
                    </p>
                    <p>purposes as stated above regarding other Non-Identifying Information.</p>
                    <p>&nbsp;</p>
                    <p>COOKIES</p>
                    <p>
                        We use data collection devices such as “cookies” on certain pages of our
                        Websites.
                    </p>
                    <p>
                        &amp;quot;Cookies&amp;quot; are small files placed on your hard drive that
                        assist us in providing customized
                    </p>
                    <p>
                        services. We also offer certain features that are only available through the
                        use of a
                    </p>
                    <p>
                        &amp;quot;cookie&amp;quot;. Cookies can also help us provide information
                        which is targeted to your
                    </p>
                    <p>
                        interests. You are always free to decline our cookies if your browser
                        permits.
                    </p>
                    <p>NOTE: EXTERNAL LINKS ON WEBSITE</p>
                    <p>
                        The Offering may include hyperlinks to other web sites or content or
                        resources.
                    </p>
                    <p>
                        Ventadoor may have no control over any websites or resources which are
                        provided by
                    </p>
                    <p>companies or persons other than Ventadoor.</p>
                    <p>
                        You acknowledge and agree that Ventadoor is not responsible for the
                        availability of any
                    </p>
                    <p>
                        such external sites or resources, and does not endorse any advertising,
                        products or
                    </p>
                    <p>other materials on or available from such websites or resources.</p>
                    <p>
                        You acknowledge and agree that Ventadoor is not liable for any loss or
                        damage which
                    </p>
                    <p>
                        may be incurred by you as a result of the availability of those external
                        sites or resources,
                    </p>
                    <p>
                        or as a result of any reliance placed by you on the completeness, accuracy
                        or existence
                    </p>
                    <p>
                        of any advertising, products or other materials on, or available from, such
                        websites or
                    </p>
                    <p>resources.</p>
                    <p>OUR USE OF YOUR INFORMATION</p>
                    <p>
                        We use information in the files we maintain about you, and the other
                        information we
                    </p>
                    <p>
                        obtain from your current and past activities on the Websites to resolve
                        disputes,
                    </p>
                    <p>
                        troubleshoot problems and enforce our Terms of Service. At times, we may
                        look across
                    </p>
                    <p>
                        multiple members to identify problems or resolve disputes, and in particular
                        we may
                    </p>
                    <p>examine your information to identify members multiple user Id or aliases.</p>
                    <p>
                        You agree that we may use personally identifiable information about you to
                        improve our
                    </p>
                    <p>
                        marketing and promotional efforts, to analyze site usage, improve our
                        content and
                    </p>
                    <p>
                        product offerings, and customize our Site&amp;#39;s content, layout, and
                        services. These uses
                    </p>
                    <p>
                        improve our site and better tailor it to meet your needs.You agree that we
                        may use your
                    </p>
                    <p>
                        information to contact you and deliver information to you that, in some
                        cases, are
                    </p>
                    <p>
                        targeted to your interests, such as targeted banner advertisements,
                        administrative
                    </p>
                    <p>
                        notices, product offerings, and communications relevant to your use on the
                        Websites. By
                    </p>
                    <p>
                        accepting this Privacy Policy, you expressly agree to receive this
                        information.
                    </p>
                    <p>
                        You agree that your activities like ‘liking venues’, ‘reviewing venues’ or
                        any
                    </p>
                    <p>
                        otheractivities arising from your use of social networking features on
                        ventadoor will be
                    </p>
                    <p>
                        published publicly to enable better discovery of products, venues etc for
                        other users.
                    </p>
                    <p>CONFIDENTIALITY</p>
                    <p>
                        You further acknowledge that the Offering may contain information which is
                        designated
                    </p>
                    <p>
                        confidential by ventadoor and that you shall not disclose such information
                        without
                    </p>
                    <p>
                        ventadoor&amp;#39;s prior written consent. Your information is regarded as
                        confidential and
                    </p>
                    <p>
                        therefore will not be divulged to any third party, unless if legally
                        required to do so to the
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        appropriate authorities. We may not sell, share, or rent your personal
                        information to any
                    </p>
                    <p>
                        third party or use your e-mail address for unsolicited mail. Any emails sent
                        by us will only
                    </p>
                    <p>be in connection with the provision of agreed services and products.</p>
                    <p>OUR DISCLOSURE OF YOUR INFORMATION</p>
                    <p>
                        Due to the existing regulatory environment, we cannot ensure that all of
                        your private
                    </p>
                    <p>
                        communications and other personally identifiable information will never be
                        disclosed in
                    </p>
                    <p>
                        ways not otherwise described in this Privacy Policy. By way of example
                        (without limiting
                    </p>
                    <p>
                        and foregoing), we may be forced to disclose information to the government,
                        law
                    </p>
                    <p>
                        enforcement agencies or third parties. Under certain circumstances, third
                        parties may
                    </p>
                    <p>
                        unlawfully intercept or access transmissions or private communications, or
                        members
                    </p>
                    <p>
                        may abuse or misuse your information that they collect from our Websites.
                        Therefore,
                    </p>
                    <p>
                        although we use industry standard practices to protect your privacy, we do
                        not promise,
                    </p>
                    <p>
                        and you should not expect, that your personally identifiable information or
                    </p>
                    <p>
                        privatecommunications will always remain private. As a matter of policy, we
                        do not sell
                    </p>
                    <p>
                        or rent any personally identifiable information about you to any third
                        party. However, the
                    </p>
                    <p>
                        following describes some of the ways that your personally identifiable
                        information may
                    </p>
                    <p>be disclosed.</p>
                    <p>
                        Advertiser: We aggregate (gather up data across all our user accounts)
                        personally
                    </p>
                    <p>
                        identifiable information and disclose such information in a non-personally
                        identifiable
                    </p>
                    <p>
                        manner to advertisers and other third parties for other marketing and
                        promotional
                    </p>
                    <p>
                        purposes. However, in these situations, we do not disclose to these entities
                        any
                    </p>
                    <p>
                        information that could be used to identify you personally. Certain
                        information, such as
                    </p>
                    <p>
                        your name, email address, password, credit card number and bank account
                        number, are
                    </p>
                    <p>
                        never disclosed to marketing advertisers. We may use third-party advertising
                        companies
                    </p>
                    <p>
                        to serve ads on our behalf. These companies may employ cookies and action
                        tags (also
                    </p>
                    <p>
                        known as single pixel gifs or web beacons) to measure advertising
                        effectiveness. Any
                    </p>
                    <p>
                        information that these third parties collect via cookies and action tags is
                        completely
                    </p>
                    <p>anonymous.</p>
                    <p>
                        External Service Providers: There may be a number of services offered by
                        external
                    </p>
                    <p>
                        service providers that help you use our Websites. If you choose to use these
                        optional
                    </p>
                    <p>
                        services, and in the course of doing so, disclose information to the
                        external service
                    </p>
                    <p>
                        providers, and/or grant them permission to collect information about you,
                        then their use
                    </p>
                    <p>of your information is governed by their private policy.</p>
                    <p>
                        Other Corporate Entities: We share much of our data, including personally
                        identifiable
                    </p>
                    <p>
                        information about you, with our parent and/or subsidiaries that are
                        committed to serving
                    </p>
                    <p>
                        your online needs and related services, throughout the world. To the extent
                        that these
                    </p>
                    <p>
                        entities have access to your information, they will treat it at least as
                        protectively as they
                    </p>
                    <p>
                        treat information they obtain from their other members. Our parent and/or
                        subsidiaries
                    </p>
                    <p>
                        will follow privacy practices no less protective of all members than our
                        practices
                    </p>
                    <p>
                        described in this document, to the extent allowed by applicable law. It is
                        possible that
                    </p>
                    <p>
                        We and/or its subsidiaries, or any combination of such, could merge with or
                        be acquired
                    </p>
                    <p>
                        by another business entity. Should such a combination occur, you should
                        expect that we
                    </p>
                    <p>
                        would share some or all of your information in order to continue to provide
                        the service.
                    </p>
                    <p>You will receive notice of such event (to the extent it occurs).</p>
                    <p>
                        Members and Venue Owners: If you create a Listing, Ventadoor Users will see
                        the
                    </p>
                    <p>
                        content of your Listing and your contact information. You can choose what
                        Personal
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Information you provide as part of your Listing. We recommend that you guard
                        your
                    </p>
                    <p>
                        sensitive information. As a Member, if you request a Venue Visit, request a
                        Venue
                    </p>
                    <p>
                        Booking Fee quote or make a booking via the Services, we may share your
                        name,
                    </p>
                    <p>
                        phone number and email address with the applicable Venue Owner in order to
                        fulfill your
                    </p>
                    <p>request or booking, as applicable.</p>
                    <p>
                        Bookings: If you make a booking of a professional via the Services, we will
                        share your
                    </p>
                    <p>
                        Personal Information, including, but not limited to, your name and email
                        address, as well
                    </p>
                    <p>
                        as other information about your booking, (e.g. the type of requirement and
                        the user
                    </p>
                    <p>
                        needs) with the applicable professional service provider so that such
                        professional can
                    </p>
                    <p>co-ordinate the booking with you.</p>
                    <p>
                        Ventadoor Partners: We may also provide Personal Information to our business
                    </p>
                    <p>
                        partners or other trusted entities for the purpose of providing you with
                        information on
                    </p>
                    <p>
                        goods or services we believe will be of interest to you. You can, at any
                        time, opt out of
                    </p>
                    <p>receiving such communications.</p>
                    <p>
                        Social Networking Services: Members and professionals may link their
                        Accounts with
                    </p>
                    <p>
                        certain SOCIAL NEYWORKING SITE. In these instances, with your permission and
                        on
                    </p>
                    <p>
                        your behalf, we will share information about your activities on the Site and
                        Service,
                    </p>
                    <p>
                        which may include your Personal Information, including, but not limited to,
                        information
                    </p>
                    <p>
                        contained in any Listings, with the applicable Social Networking Service.
                        Through use of
                    </p>
                    <p>
                        such services/APIs, as stated above, we also receive certain information
                        (which may
                    </p>
                    <p>
                        include your Personal Information) in accordance with the privacy settings
                        you have set
                    </p>
                    <p>
                        in your Social Networking Site account, if any. Other than what we may share
                        with the
                    </p>
                    <p>
                        Social Networking Site in connection with your linking of accounts, the
                        personal
                    </p>
                    <p>
                        information a Social Networking Site has about you is obtained by the Social
                        Networking
                    </p>
                    <p>
                        Site independent of our Service. Other services follow different rules
                        regarding the use
                    </p>
                    <p>
                        or disclosure of the personal information you submit to them. We encourage
                        you to read
                    </p>
                    <p>the privacy policies or statements of the other services you use.</p>
                    <p>
                        Law and Order: We cooperate with law enforcement inquiries, as well as other
                        third
                    </p>
                    <p>
                        parties to enforce laws, such as: intellectual property rights, fraud and
                        other rights. We
                    </p>
                    <p>
                        can (and you authorize us to) disclose any information about you to law
                        enforcement
                    </p>
                    <p>
                        and other government officials as we, in our sole discretion, believe
                        necessary or
                    </p>
                    <p>
                        appropriate, in connection with an investigation of fraud, intellectual
                        property
                    </p>
                    <p>
                        infringements, or other activity that is illegal or may expose us or you to
                        legal liability.
                    </p>
                    <p>YOUR USE OF OTHER MEMBERS INFORMATION</p>
                    <p>
                        Our services may also include access to instant messaging and chat rooms. As
                        a
                    </p>
                    <p>
                        member you have access to the User’s ID, and you might gain access to other
                        contact
                    </p>
                    <p>
                        information of the User(s) through the regular use of the instant messaging
                        and chat
                    </p>
                    <p>
                        rooms. By accepting this Privacy Policy, you agree that, with respect to
                        other Members&amp;#39;
                    </p>
                    <p>
                        personally identifiable information that you obtain through instant
                        messaging
                    </p>
                    <p>
                        communication, the chat rooms and email, We hereby grants to you a license
                        to use
                    </p>
                    <p>
                        such information only for: (a) Your Company related communications that are
                        not
                    </p>
                    <p>
                        unsolicited commercial messages, and (b) any other purpose that such member
                    </p>
                    <p>
                        expressly agrees to after adequate disclosure of the purpose(s). In all
                        cases, you must
                    </p>
                    <p>
                        give Members an opportunity to remove themselves from your database and a
                        chance to
                    </p>
                    <p>
                        review what information you have collected about them. In addition, under no
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        circumstances, except as defined in this Section, can you disclose
                        personally identifiable
                    </p>
                    <p>
                        information about another member to any third party without our consent and
                        the
                    </p>
                    <p>
                        consent of such other member after adequate disclosure. Ventadoor and our
                        users do
                    </p>
                    <p>
                        not tolerate spam. Therefore, without limiting the foregoing, you are not
                        licensed to add
                    </p>
                    <p>
                        vventadoor.com site user to your mail list (email or physical mail) without
                        their express
                    </p>
                    <p>written consent after adequate disclosure.</p>
                    <p>
                        You understand that all information (such as data files, written text,
                        computer software,
                    </p>
                    <p>
                        or images) which you may have access to as part of, or through your use of,
                        the Offering
                    </p>
                    <p>
                        are the sole responsibility of the person from which such content
                        originated.
                    </p>
                    <p>Our Policy Toward Children</p>
                    <p>
                        The Service is not directed to persons under 18. We do not knowingly collect
                        personally
                    </p>
                    <p>
                        identifiable information from children under 13. If a parent or guardian
                        becomes aware
                    </p>
                    <p>
                        that his or her child has provided us with Personal Information without
                        their consent, he
                    </p>
                    <p>
                        or she should contact us at info@ventadoor.com. If we become aware that a
                        child under
                    </p>
                    <p>
                        13 has provided us with Personal Information, we will delete such
                        information from our
                    </p>
                    <p>files.</p>
                    <p>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</p>
                    <p>
                        Following registration, you can review and change the information you
                        submitted during
                    </p>
                    <p>
                        registration through the ‘Edit Profile’. If you change any information we
                        may keep track of
                    </p>
                    <p>
                        your old information. You can also change your registration information such
                        as: name,
                    </p>
                    <p>
                        address, city, state, zip code, country, phone number, profile, likes and
                        dislikes, desired
                    </p>
                    <p>
                        date profile, essays and saved search criteria.To remove your profile so
                        that others
                    </p>
                    <p>cannot view it, contact our customer support team at info@ventadoor.com</p>
                    <p>
                        We will retain in our files information you have requested to remove for
                        certain
                    </p>
                    <p>
                        circumstances, such as to resolve disputes, troubleshoot problems and
                        enforce our
                    </p>
                    <p>
                        Terms of Service. Further, such prior information is never completely
                        removed from our
                    </p>
                    <p>
                        databases due to technical and legal constraints, including stored
                        &amp;#39;back up&amp;#39; systems.
                    </p>
                    <p>
                        Therefore, you should not expect that all of your personally identifiable
                        information will
                    </p>
                    <p>be completely removed from our databases in response to your requests.</p>
                    <p>CONTROL OF YOUR PASSWORD</p>
                    <p>
                        You are responsible for all actions taken with your login information and
                        password,
                    </p>
                    <p>
                        including fees. Therefore we do not recommend that you disclose your account
                    </p>
                    <p>
                        password or login information to any third parties. If you choose to share
                        this information
                    </p>
                    <p>
                        with third parties to provide you additional services, you are responsible
                        for all actions
                    </p>
                    <p>
                        taken with your login information and password and therefore should review
                        each third
                    </p>
                    <p>
                        party&amp;#39;s privacy policy. If you lose control of your password, you
                        may lose substantial
                    </p>
                    <p>
                        control over your personally identifiable information and may be subject to
                        legally binding
                    </p>
                    <p>
                        actions taken on your behalf. Therefore, if your password has been
                        compromised for any
                    </p>
                    <p>reason, you should immediately change your password.</p>
                    <p>OTHER INFORMATION COLLECTORS</p>
                    <p>
                        Except as otherwise expressly included in this Privacy Policy, this document
                        only
                    </p>
                    <p>
                        addresses the use and disclosure of information we collect from you. To the
                        extent that
                    </p>
                    <p>
                        you disclose your information to other parties, whether they are on our
                        Websites or on
                    </p>
                    <p>
                        other sites throughout the Internet, different rules may apply to their use
                        or disclosure of
                    </p>
                    <p>
                        the information you disclose to them. To the extent that we use third party
                        advertisers,
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        they adhere to their own privacy policies. Since we do not control the
                        privacy policies of
                    </p>
                    <p>
                        the third parties, you are subject to ask questions before you disclose your
                        personal
                    </p>
                    <p>information to others.</p>
                    <p>SECURITY</p>
                    <p>
                        We treat data as an asset that must be protected against loss and
                        unauthorized access.
                    </p>
                    <p>
                        We employ many different security techniques to protect such data from
                        unauthorized
                    </p>
                    <p>
                        access by members inside and outside the company. However, &amp;quot;perfect
                        security&amp;quot; does
                    </p>
                    <p>
                        not exist on the Internet. You therefore agree that any security breaches
                        beyond the
                    </p>
                    <p>
                        control of our standard security procedures are at your sole risk and
                        discretion.
                    </p>
                    <p>&nbsp;</p>
                    <p>NOTIFICATION OF MODIFICATIONS AND CHANGES TO THE TERMS OF SERVICE</p>
                    <p>AND PRIVACY POLICY</p>
                    <p>
                        We reserve the right to change the Terms and Privacy Policy from time to
                        time as it sees
                    </p>
                    <p>
                        fit and your continued use of the site will signify your acceptance of any
                        adjustment to
                    </p>
                    <p>
                        these terms. We may not announce such changes on our website. Therefore, you
                        agree
                    </p>
                    <p>
                        that you will review our Terms of Service and Privacy Policy documents on a
                        regular
                    </p>
                    <p>basis.</p>
                    <p>
                        Should it be that you do not accept any of the modifications or amendments
                        to the
                    </p>
                    <p>Terms, you may terminate your use of this website immediately.</p>
                    <p>Contacting Us</p>
                    <p>If you have any questions about this Privacy Policy, please contact us at</p>
                    <p>info@Ventadoor.com.</p>{' '}
                </div>
            </section>
        </>
    );
}

export default Content;
