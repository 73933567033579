import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FooterHomeOne from '../Home/FooterHomeOne';
import Content from './Content';
import HomeOneHeader from '../Home/HomeOneHeader';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <Content />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
