import React from 'react';
import workThumb from '../../assets/images/kooky/start-thumb.png';

function WorkPartHomeEight() {
    return (
        <>
            <section id="start" className="appie-how-it-work-area pt-10 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={workThumb} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-how-it-work-content">
                                <h2 className="title">Hire legal experts in your location</h2>
                                <p>
                                    You can search for professionals by location, expertise, and
                                    availability. Once you find a professional you're interested in,
                                    you can book an appointment online
                                </p>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>1</span>
                                            <h5 className="title">
                                                Services in <br />
                                                your region
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>2</span>
                                            <h5 className="title">
                                                Learning courses <br /> with certification
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>3</span>
                                            <h5 className="title">Concrete Security.</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>4</span>
                                            <h5 className="title">Premium Support</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WorkPartHomeEight;
