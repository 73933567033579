import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                <h2 className="title">
                                    The Leading Marketplace for Professional Services
                                </h2>
                                <p>
                                    At Ventadoor, we are revolutionizing the way people access and
                                    offer services. With a vision to make life more convenient and
                                    efficient, we have created a cutting-edge marketplace app that
                                    connects users with a diverse range of service providers, all at
                                    their fingertips.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-content">
                                <h3 className="title">
                                    Join our team to create the best digital solutions.
                                </h3>
                                <p>
                                    Our mission is simple yet powerful - to empower individuals by
                                    giving them the ability to schedule, book, and manage services
                                    effortlessly. We aim to foster a community where users can
                                    access top-notch professional services while professionals can
                                    showcase their expertise and grow their businesses.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
